//@ts-ignore
import React, { useCallback, useReducer } from 'react'
import Prismic from 'prismic-javascript'
import ApiSearchResponse from 'prismic-javascript/types/ApiSearchResponse'
import constate from 'constate'

export type Hero = {
  main_caption: string
  second_caption: string
  image: string
  active: boolean
}

type HeroState = {
  heros: Hero[]
}

type Action = {
  type: 'SET_HEROS'
  payload: Hero[]
}

const initialState: HeroState = {
  heros: [],
}

const reducer = (state: HeroState, action: Action) => {
  switch (action.type) {
    case 'SET_HEROS':
      return { ...state, heros: action.payload }
    default:
      return state
  }
}

const useHeroContent = () => {
  const apiEndpoint = `${process.env.REACT_APP_PRISMIC_API_ENDPOINT}`
  const accessToken = `${process.env.REACT_APP_PRISMIC_ACCESS_TOKEN}`

  const Client = Prismic.client(apiEndpoint, { accessToken })

  const [state, dispatch] = useReducer(reducer, initialState)

  const loadHeros = useCallback(
    async (documentType: string) => {
      try {
        const response: ApiSearchResponse = await Client.query(
          Prismic.Predicates.at('document.type', documentType),
          { lang: '*' }
        )

        if (response) {
          const heros: Hero[] = []
          response.results.map((hero: any, index) => {
            const item = {
              main_caption: hero.data.main_caption[0].text,
              second_caption: hero.data.second_caption[0].text,
              image: hero.data.hero.url,
              active: index === 0 ? true : false,
            }
            heros.push(item)
          })

          dispatch({ type: 'SET_HEROS', payload: heros })
        }
      } catch (err) {
        console.log(err)
      }
    },
    [dispatch]
  )

  return { ...state, dispatch, loadHeros }
}

const [HeroProvider, useHeroContext] = constate(useHeroContent)

export { HeroProvider, useHeroContext }
