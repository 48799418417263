import React, { useEffect, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import { Link, useLocation } from 'react-router-dom'

import { useAuth } from './../../auth/AuthContext'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Popper from '@material-ui/core/Popper'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { Paper, Grow } from '@material-ui/core'
import Authenticate from '../Authenticate'

import closesnav from './../../assets/images/shapes/close-1-1.png'
import aeromar_light from './../../assets/images/comviajesaeromar-white.png'
import aeromar_dark from './../../assets/images/comviajesaeromar-blue.png'
import { useTripContext } from '../../context/TripContext'

const Header = () => {
  const location = useLocation()
  const { signOut, isAuthenticated, user } = useAuth()
  const { langs, changeLanguage } = useTripContext()

  const [mobileNav, setMobileNav] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const [modalAuth, setModalAuth] = useState<boolean>(false)
  const [scrollPosition, setSrollPosition] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const position = window.pageYOffset
    setSrollPosition(position)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef &&
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleToggleModal = (action?: boolean) => {
    setModalAuth(action ? action : !modalAuth)
  }

  const handleLangChange = (event: React.FormEvent<HTMLSelectElement>) => {
    changeLanguage(event.currentTarget.value)
  }

  const onLogout = () => {
    signOut()
  }

  return (
    <>
      <div
        className={
          location.pathname === '/' ? 'site-header__home-two-wrap' : ''
        }
      >
        <div className="topbar-one">
          <div className="container-fluid">
            <div className="topbar-one__left">
              <Link to="mailto:contacto@viajesaeromar.com">
                contacto@viajesaeromar.com
              </Link>
              <Link to="tel:+573136724959">+57 3136724959</Link>
              <span>Medellin, Colombia</span>
            </div>
            <div className="topbar-one__right">
              <div className="topbar-one__social">
                <a
                  href="https://wa.me/573136724959?text=Hola%2C%20estoy%20interesad%40%20en%20los%20servicios%20turísticos%20que%20publicas%20en%20viajesaeromar.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-whatsapp"></i>
                </a>
                {/* <Link to={''}>
              <i className="fa fa-instagram"></i>
              </Link> */}
                <a
                  href="https://www.facebook.com/viajesaeromarsgv"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-facebook-square"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <header
          className={
            (location.pathname === '/'
              ? 'main-nav__header-one site-header__home-two'
              : 'main-nav__header-one') +
            (scrollPosition > 220 ? ' main-nav__fixed' : '')
          }
        >
          <nav className="header-navigation stricky">
            <div className="container">
              <div className="main-nav__logo-box">
                <Link
                  to={'/'}
                  className="main-nav__logo"
                  style={{ color: '#fff', fontSize: '23px' }}
                >
                  {location.pathname === '/' ? (
                    <img
                      src={aeromar_light}
                      className="main-logo"
                      alt="Logo"
                      width="250px"
                    />
                  ) : location.pathname !== '/' && scrollPosition <= 220 ? (
                    <img
                      src={aeromar_dark}
                      className="main-logo"
                      alt="Logo"
                      width="230px"
                    />
                  ) : (
                    <img
                      src={aeromar_light}
                      className="main-logo"
                      alt="Logo"
                      width="250px"
                    />
                  )}
                </Link>

                <Link
                  to={''}
                  className="side-menu__toggler"
                  onClick={() => setMobileNav(!mobileNav)}
                >
                  <i className="fa fa-bars"></i>
                </Link>
              </div>

              <div className="main-nav__main-navigation">
                <Menu />
              </div>

              <div className="main-nav__right">
                <select name="currency" id="currency" className="selectpicker">
                  <option value="USD">USD</option>
                  <option value="GBP">GBP</option>
                  <option value="AUD">AUD</option>
                </select>
                <div className="select">
                  <select
                    name="languages"
                    id="languages"
                    onChange={handleLangChange}
                  >
                    {langs.length &&
                      langs.map((lang: any, index: number) => (
                        <option value={lang.split('-')[0]} key={index}>
                          {lang.split('-')[0]}
                        </option>
                      ))}
                  </select>
                </div>
                {/*  <div
                  style={{
                    color: '#fff',
                    cursor: 'pointer',
                    marginRight: 0,
                    marginLeft: '30px',
                    fontSize: '21px',
                  }}
                >
                  <i
                    className="tripo-icon-magnifying-glass"
                    onClick={() => setModalAuth(!modalAuth)}
                  ></i>
                </div> */}
                {!isAuthenticated ? (
                  <div
                    style={{
                      color: '#fff',
                      cursor: 'pointer',
                      marginRight: 0,
                      marginLeft: '30px',
                      fontSize: '21px',
                    }}
                  >
                    <i
                      className="tripo-icon-avatar"
                      onClick={() => setModalAuth(!modalAuth)}
                    ></i>
                  </div>
                ) : (
                  <>
                    {user && (
                      <Button
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        size="small"
                        style={{
                          fontFamily: 'Barlow Condensed',
                          color: '#fff',
                          cursor: 'pointer',
                          marginRight: 0,
                          marginLeft: '30px',
                          fontSize: '16px',
                        }}
                      >
                        <Avatar
                          alt={user && user.attributes.name}
                          src={
                            user.attributes.picture ||
                            JSON.parse(user && user.attributes.picture).data.url
                          }
                          style={{ marginRight: '5px' }}
                        />
                        {user && user.attributes.name.split(' ')[0]}
                      </Button>
                    )}
                    <Popper
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom'
                                ? 'center top'
                                : 'center bottom',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                autoFocusItem={open}
                                id="menu-list-grow"
                                onKeyDown={handleListKeyDown}
                              >
                                {/* <MenuItem onClick={() => {handleToggle(); history.push('/profile')}}>Profile</MenuItem> */}
                                <MenuItem onClick={onLogout}>Logout</MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                )}
              </div>
            </div>
          </nav>
        </header>

        <div className={!modalAuth ? 'search-popup' : 'search-popup active'}>
          <div className="search-popup__overlay custom-cursor__overlay">
            <i
              className="fa fa-times"
              style={{
                position: 'absolute',
                right: '85px',
                top: '85px',
                fontSize: '21px',
                cursor: 'pointer',
              }}
              onClick={() => setModalAuth(!modalAuth)}
            ></i>
          </div>
          <div className="search-popup__inner">
            {/* {modalAuth && ( */}

            <Authenticate closeModal={handleToggleModal} />

            {/* )} */}
          </div>
        </div>
      </div>

      {/* Mobile Nav */}
      <div className={'side-menu__block' + (mobileNav ? ' active' : '')}>
        <div className="side-menu__block-overlay custom-cursor__overlay">
          <div className="cursor"></div>
          <div className="cursor-follower"></div>
        </div>
        <div className="side-menu__block-inner ">
          <div className="side-menu__top justify-content-end">
            <Link
              to="#"
              className="side-menu__toggler side-menu__close-btn"
              onClick={() => setMobileNav(!mobileNav)}
            >
              <img src={closesnav} alt="" />
            </Link>
          </div>

          <nav className="mobile-nav__container">
            <Menu />
          </nav>
          <div className="side-menu__sep"></div>
          <div className="side-menu__content">
            {/* <p>
              Agencia de Viajes y Turismo - Viajes Aeromar Con nosotros
              encontrarás productos y servicios turísticos, tours, viajes,
              aventuras de nuestras agencias aliadas.
            </p> */}
            <p>
              <Link to="mailto:contacto@viajesaeromar.com">
                contacto@viajesaeromar.com
              </Link>
              <br />
              <Link to="tel:+573136724959">+57 3136724959</Link>
            </p>
            <div className="side-menu__social">
              <a
                href="https://wa.me/573136724959?text=Hola%2C%20estoy%20interesad%40%20en%20los%20servicios%20turísticos%20que%20publicas%20en%20viajesaeromar.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-whatsapp"></i>
              </a>
              {/* <Link to={''}>
              <i className="fa fa-instagram"></i>
              </Link> */}
              <a
                href="https://www.facebook.com/viajesaeromarsgv"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook-square"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header

const Menu = () => {
  return (
    <ul className=" main-nav__navigation-box">
      <li>
        <Link to={'/'}>Home</Link>
      </li>
      {/* <li>
        <Link to="/contact">Contact</Link>
      </li> */}
    </ul>
  )
}
