//@ts-ignore
import React, { useCallback, useEffect, useReducer } from 'react'
import Prismic from 'prismic-javascript'
import ApiSearchResponse from 'prismic-javascript/types/ApiSearchResponse'
import { I18n } from '@aws-amplify/core'
import constate from 'constate'

export type Trip = {
  id: string
  name: string
  overview?: string
  includes?: string[]
  excludes?: string[]
  price: number
  days: number
  cities: string[]
  images?: string[]
  rate: string
}

type AppState = {
  langs: any[]
  selectedLang: string
  dict: any
  trips: any[]
}

type Action =
  | {
      type: 'QUERY'
      payload: Trip[]
    }
  | {
      type: 'SET_LANGUAGES'
      payload: any[]
    }
  | {
      type: 'CHANGE_LANGUAGE'
      payload: string
    }
  | {
      type: 'SET_DICT'
      payload: any
    }
  | {
      type: 'SUBSCRIPTION'
      payload: Trip
    }
  | {
      type: 'SET_TRIP'
      payload: any
    }

const initialState: AppState = {
  langs: [],
  selectedLang: 'es',
  trips: [],
  dict: {},
}

const reducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case 'QUERY':
      return { ...state, trips: action.payload }
    case 'SET_LANGUAGES':
      return { ...state, langs: action.payload }
    case 'CHANGE_LANGUAGE':
      return { ...state, selectedLang: action.payload }
    case 'SET_DICT':
      return { ...state, dict: action.payload }
    case 'SET_TRIP':
      return { ...state, trips: [...state.trips, action.payload] }

    default:
      return state
  }
}

const apiEndpoint = `${process.env.REACT_APP_PRISMIC_API_ENDPOINT}`
const accessToken = `${process.env.REACT_APP_PRISMIC_ACCESS_TOKEN}`

const useTripState = () => {
  const Client = Prismic.client(apiEndpoint, { accessToken })
  const [state, dispatch] = useReducer(reducer, initialState)
  I18n.setLanguage(state.selectedLang)

  useEffect(() => {
    const dict = {
      es: {
        trips: state.trips.filter((trip) => trip.lang === 'es-co'),
      },
      en: {
        trips: state.trips.filter((trip) => trip.lang === 'en-us'),
      },
    }

    dispatch({ type: 'SET_DICT', payload: dict })
    //I18n.putVocabularies(dict)
  }, [state.trips])

  const loadTrips = useCallback(async () => {
    try {
      const response: ApiSearchResponse = await Client.query(
        Prismic.Predicates.at('document.type', 'trip'),
        { lang: '*' }
      )

      if (response) {
        dispatch({
          type: 'SET_LANGUAGES',
          payload: response.results
            .map((item) => item.lang)
            .filter((value, index, self) => self.indexOf(value) === index),
        })

        response.results.map((result: any) => {
          dispatch({ type: 'SET_TRIP', payload: result })
        })
      }
    } catch (err) {
      console.log(err)
    }
  }, [dispatch])

  const changeLanguage = (lang: string) => {
    dispatch({ type: 'CHANGE_LANGUAGE', payload: lang })
  }

  const setAvailableLanguages = (langs: any) => {
    dispatch({
      type: 'SET_LANGUAGES',
      payload: langs,
    })
  }

  const setStateDict = (dict: any) => {
    dispatch({ type: 'SET_DICT', payload: dict })
  }

  return {
    ...state,
    dispatch,
    loadTrips,
    changeLanguage,
    setAvailableLanguages,
    setStateDict,
  }
}

const [TripProvider, useTripContext] = constate(useTripState)

export { TripProvider, useTripContext }
