import React, { useCallback, useEffect, useReducer } from 'react'
import Prismic from 'prismic-javascript'
import ApiSearchResponse from 'prismic-javascript/types/ApiSearchResponse'
import { I18n } from '@aws-amplify/core'
import constate from 'constate'
import { useTripContext } from './TripContext'

const apiEndpoint = `${process.env.REACT_APP_PRISMIC_API_ENDPOINT}`
const accessToken = `${process.env.REACT_APP_PRISMIC_ACCESS_TOKEN}`

type TourTypeList = {
  category: any[]
  travel_type: any[]
  uid: string
}

type AppState = {
  tourTypeList: TourTypeList[]
  cont: any
}

type Action =
  | {
      type: 'SET_TOUR_TYPE_LIST'
      payload: TourTypeList[]
    }
  | { type: 'SET_CONTENT'; payload: any }

const reducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case 'SET_TOUR_TYPE_LIST':
      return { ...state, tourTypeList: action.payload }
    case 'SET_CONTENT':
      return { ...state, cont: action.payload }
    default:
      return state
  }
}

const initialState: AppState = {
  tourTypeList: [],
  cont: {},
}

const useContentState = () => {
  const Client = Prismic.client(apiEndpoint, { accessToken })
  const [state, dispatch] = useReducer(reducer, initialState)
  const { setStateDict, trips } = useTripContext()

  useEffect(() => {
    const dict = {
      es: {
        trips: trips.filter((trip: any) => trip.lang === 'es-co'),
        exploreBox: state.cont['es'],
      },
      en: {
        trips: trips.filter((trip: any) => trip.lang === 'en-us'),
        exploreBox: state.cont['en'],
      },
    }
    setStateDict(dict)
    I18n.putVocabularies(dict)
  }, [trips, state])

  const listTypeTours = useCallback(async () => {
    try {
      const response: ApiSearchResponse = await Client.query(
        Prismic.Predicates.at('document.type', 'search_by_type_box'),
        { lang: '*' }
      )

      if (response) {
        dispatch({
          type: 'SET_TOUR_TYPE_LIST',
          payload: response.results as any,
        })

        response.results.map((item) => item.lang)
      }
    } catch (err) {
      console.log(err)
    }
  }, [dispatch])

  const loadContent = useCallback(
    async (documentType: string) => {
      try {
        const response: ApiSearchResponse = await Client.query(
          Prismic.Predicates.at('document.type', documentType),
          { lang: '*' }
        )

        if (response) {
          const languages = response.results.map(
            (result) => result.lang && result.lang.split('-')[0]
          ) as string[]

          const dict = response.results.reduce(
            (result: any, key: any) => ({
              ...result,
              [key.lang && key.lang.split('-')[0]]: Object.entries(
                key.data
              ).reduce(
                (field: any, i: any) => ({
                  ...field,
                  [i[0]]: i[1].length
                    ? i[1].map((c: any) => `${c.text}`).join(' ')
                    : i[1],
                }),
                {}
              ),
            }),
            {}
          )

          dispatch({ type: 'SET_CONTENT', payload: dict })
        }
      } catch (e) {
        console.log(e)
      }
    },
    [dispatch]
  )

  return { ...state, listTypeTours, loadContent }
}

const [ContentProvider, useContentContext] = constate(useContentState)

export { ContentProvider, useContentContext }
