import React, { useEffect, useReducer, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTripContext } from '../context/TripContext'

import {
  faUserCircle,
  faBookmark,
  faMap,
  faHeart,
} from '@fortawesome/free-solid-svg-icons'

import { API, graphqlOperation } from 'aws-amplify'
import { createAccount, createBooking } from '../graphql/mutations'
import { listAccounts } from '../graphql/queries'
import { CreateAccountMutation, ListAccountsQuery } from '../API'

type TParams = { id: string }

type Account = {
  documentType?: string
  document?: string
  name: string
  email: string
  phone?: string
  birthday?: string
}

type Booking = {
  accountID?: string
  tourID?: string
  startDate?: string
  endDate?: string
}

type AppState = {
  loading: boolean
  formAccount: Account
  formBook: Booking
}

type Action =
  | {
      type: 'SET_LOADING'
      payload: boolean
    }
  | {
      type: 'SET_BOOK'
      payload: { [field: string]: string }
    }
  | {
      type: 'SET_ACCOUNT'
      payload: { [field: string]: string }
    }
  | {
      type: 'RESET'
    }

const initialState: AppState = {
  loading: false,
  formAccount: {
    name: '',
    email: '',
    phone: '',
  },
  formBook: {},
}

const reducer = (state: AppState, action: Action) => {
  if (action.type === 'RESET') {
    return initialState
  }

  switch (action.type) {
    case 'SET_ACCOUNT':
      return {
        ...state,
        formAccount: { ...state.formAccount, ...action.payload },
      }
    case 'SET_BOOK':
      return { ...state, formBook: { ...state.formBook, ...action.payload } }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      return state
  }
}

const TripDetail = ({ match }: RouteComponentProps<TParams>) => {
  const { trips, selectedLang, loadTrips } = useTripContext()
  const [trip, setTrip] = useState<any | undefined>()
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (!trips.length) fetchTrips()
  }, [])

  useEffect(() => {
    setTrip(
      trips
        .filter((trip: any) => trip.lang.split('-')[0] === selectedLang)
        .find((trip: any) => trip.id === match.params.id)
    )
  }, [trips])

  useEffect(() => {
    dispatch({
      type: 'SET_BOOK',
      payload: { ['tourID']: trip?.id },
    })
  }, [trip])

  const fetchTrips = async () => {
    await loadTrips()
  }

  const handleAccountChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({
      type: 'SET_ACCOUNT',
      payload: { [e.target.name]: e.target.value },
    })

  const submitBook = async (e: any) => {
    e.preventDefault()
    if (
      state.formAccount.name === '' &&
      state.formAccount.email === '' &&
      state.formAccount.phone === ''
    ) {
      console.log('error: non empty fields for account')
      return false
    }

    dispatch({ type: 'SET_LOADING', payload: true })

    try {
      const { email } = state.formAccount
      let accountID = null

      const account = (await API.graphql(
        graphqlOperation(listAccounts, { filter: { email: { eq: email } } })
      )) as { data: ListAccountsQuery }

      if (account.data.listAccounts?.items?.length) {
        accountID = account.data.listAccounts.items[0]?.id
      } else {
        const response = (await API.graphql(
          graphqlOperation(createAccount, { input: { ...state.formAccount } })
        )) as { data: CreateAccountMutation }

        accountID = response.data.createAccount?.id
      }

      if (accountID) {
        await API.graphql(
          graphqlOperation(createBooking, {
            input: { ...state.formBook, accountID: accountID },
          })
        )
      }

      dispatch({ type: 'RESET' })
      dispatch({ type: 'SET_LOADING', payload: false })
    } catch (err) {
      console.log(err)
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  return (
    <section className="tour-two tour-list">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="tour-details__content">
              <div className="tour-two__top">
                <div className="tour-two__top-left">
                  <h3>{trip?.data['trip-title'][0].text}</h3>
                  <div className="tour-one__stars">
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star inactive"></i> 2 Reviews
                  </div>
                </div>
                <div className="tour-two__right">
                  <p>
                    <span>
                      {trip?.data['price'] &&
                        `$ ${trip?.data['price'].toLocaleString()}`}
                    </span>{' '}
                    <br />
                    {`${trip?.data['currency']}`} Por Persona
                  </p>
                </div>
              </div>
              <ul className="tour-one__meta list-unstyled">
                <li>
                  <i className="fa fa-clock"></i>
                  {/* <FontAwesomeIcon icon={faClock} />{' '} */}
                  {`${trip?.data['days'][0].text} Días `}
                </li>
                <li>
                  <FontAwesomeIcon icon={faUserCircle} /> 0+
                </li>
                <li>
                  <FontAwesomeIcon icon={faBookmark} /> {trip?.data['category']}
                </li>
                <li>
                  <FontAwesomeIcon icon={faMap} />
                  {/* {trip?.cities.length && `${trip.cities[0]}`} */}
                </li>
              </ul>

              <div className="tour-details__gallery-carousel">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="tour-details__gallery-image">
                      <img src="assets/images/tour/tour-d-1-1.jpg" alt="" />
                      <div className="tour-details__gallery-links">
                        {/* <Link to={"#"}>
                          <i className="fa fa-youtube-play"></i>
                        </Link> */}
                        <Link to={'#'}>
                          <FontAwesomeIcon icon={faHeart} />
                          {/* <i className="fa fa-heart"></i> */}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="tour-details__gallery-image">
                      <img src="assets/images/tour/tour-d-1-2.jpg" alt="" />
                      <div className="tour-details__gallery-links">
                        <Link to={'#'}>
                          <i className="fa fa-youtube-play"></i>
                        </Link>
                        <Link to={'#'}>
                          <i className="fa fa-heart"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="tour-details__gallery-image">
                      <img src="assets/images/tour/tour-d-1-3.jpg" alt="" />
                      <div className="tour-details__gallery-links">
                        <Link to={'#'}>
                          <i className="fa fa-youtube-play"></i>
                        </Link>
                        <Link to={'#'}>
                          <i className="fa fa-heart"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="tour-details__gallery-image">
                      <img src="assets/images/tour/tour-d-1-4.jpg" alt="" />
                      <div className="tour-details__gallery-links">
                        <Link to={'#'}>
                          <i className="fa fa-youtube-play"></i>
                        </Link>
                        <Link to={'#'}>
                          <i className="fa fa-heart"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="tour-details__gallery-image">
                      <img src="assets/images/tour/tour-d-1-5.jpg" alt="" />
                      <div className="tour-details__gallery-links">
                        <Link to={'#'}>
                          <i className="fa fa-youtube-play"></i>
                        </Link>
                        <Link to={'#'}>
                          <i className="fa fa-heart"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tour-details__gallery-thumb-carousel">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="tour-details__gallery-thumb-image">
                      <img src="assets/images/tour/tour-thumb-1-1.jpg" alt="" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="tour-details__gallery-thumb-image">
                      <img src="assets/images/tour/tour-thumb-1-2.jpg" alt="" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="tour-details__gallery-thumb-image">
                      <img src="assets/images/tour/tour-thumb-1-3.jpg" alt="" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="tour-details__gallery-thumb-image">
                      <img src="assets/images/tour/tour-thumb-1-4.jpg" alt="" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="tour-details__gallery-thumb-image">
                      <img src="assets/images/tour/tour-thumb-1-5.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="tour-details__title">Descripción</h3>

              {trip?.data['overview'].length &&
                trip?.data['overview'].map(
                  (text: any, index: number) =>
                    text.type === 'paragraph' && <p key={index}>{text.text}</p>
                )}

              <h3 className="tour-details__subtitle">Incluye/No Incluye</h3>
              <div className="row">
                <div className="col-md-6">
                  <ul className="tour-details__list list-unstyled">
                    {trip?.data['include_group'].length &&
                      trip?.data['include_group'].map(
                        (include: any, index: number) => (
                          <li key={index}>
                            <i className="fa fa-check"></i>
                            {include.include_item}
                          </li>
                        )
                      )}
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="tour-details__list unavailable list-unstyled">
                    {trip?.data['noinclude_group'].length &&
                      trip?.data['noinclude_group'].map(
                        (noinclude: any, index: number) => (
                          <li key={index}>
                            <i className="fa fa-check"></i>
                            {noinclude.noinclude_item}
                          </li>
                        )
                      )}
                  </ul>
                </div>
              </div>
              <div className="tour-details__spacer"></div>

              {/* <div className="tour-details__spacer"></div>
              <h3 className="tour-details__title">FAQs</h3>
              <div className="accrodion-grp" data-grp-name="faq-one-accrodion">
                <div className="accrodion active">
                  <div className="accrodion-title">
                    <h4>Why are your tours so expensive?</h4>
                  </div>
                  <div className="accrodion-content">
                    <div className="inner">
                      <p>
                        There are many variations of passages of available but
                        majority have alteration in some by inject humour or
                        random words. Lorem ipsum dolor sit amet, error insolens
                        reprimique no quo, ea pri verterem phaedr vel ea iisque
                        aliquam.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accrodion ">
                  <div className="accrodion-title">
                    <h4>Which payment methods are acceptable?</h4>
                  </div>
                  <div className="accrodion-content">
                    <div className="inner">
                      <p>
                        There are many variations of passages of available but
                        majority have alteration in some by inject humour or
                        random words. Lorem ipsum dolor sit amet, error insolens
                        reprimique no quo, ea pri verterem phaedr vel ea iisque
                        aliquam.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accrodion">
                  <div className="accrodion-title">
                    <h4>How to book the new tour for 2 persons?</h4>
                  </div>
                  <div className="accrodion-content">
                    <div className="inner">
                      <p>
                        There are many variations of passages of available but
                        majority have alteration in some by inject humour or
                        random words. Lorem ipsum dolor sit amet, error insolens
                        reprimique no quo, ea pri verterem phaedr vel ea iisque
                        aliquam.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="tour-sidebar">
              <div className="tour-sidebar__search tour-sidebar__single">
                <h3>Recibe más información</h3>
                <form
                  onSubmit={submitBook}
                  className="tour-sidebar__search-form"
                >
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Nombres"
                      name="name"
                      onChange={handleAccountChange}
                      value={state.formAccount.name}
                      style={
                        state.loading ? { backgroundColor: 'gainsboro' } : {}
                      }
                      disabled={state.loading ? true : false}
                    />
                  </div>
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      onChange={handleAccountChange}
                      value={state.formAccount.email}
                      style={
                        state.loading ? { backgroundColor: 'gainsboro' } : {}
                      }
                      disabled={state.loading ? true : false}
                    />
                  </div>
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Teléfono"
                      name="phone"
                      onChange={handleAccountChange}
                      value={state.formAccount.phone}
                      style={
                        state.loading ? { backgroundColor: 'gainsboro' } : {}
                      }
                      disabled={state.loading ? true : false}
                    />
                  </div>
                  {/* <div className="input-group">
                    <input
                      type="text"
                      data-provide="datepicker"
                      placeholder="dd/mm/yy"
                    />
                  </div> */}
                  {/* <div className="input-group">
                    <select className="selectpicker">
                      <option value="Tickets">Tickets</option>
                      <option value="Children">Children</option>
                      <option value="Adult">Adult</option>
                    </select>
                  </div> */}
                  {/* <div className="input-group">
                    <textarea placeholder="Mensaje"></textarea>
                  </div> */}
                  <div className="input-group">
                    <button type="submit" className="thm-btn">
                      {state.loading ? '...Enviando' : 'Enviar'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TripDetail
