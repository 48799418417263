import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { I18n } from '@aws-amplify/core'

import 'swiper/swiper.scss'
import { useTripContext } from '../../context/TripContext'

const PopularTours = () => {
  const { dict, trips, loadTrips } = useTripContext()

  useEffect(() => {
    if (!trips.length) loadTrips()
  }, [])

  if (Object.keys(dict).length < 1) {
    return <h1>Loading...</h1>
  }

  return (
    <section className="tour-one">
      <div className="container">
        <div className="block-title text-center">
          {/* <p>Featured tours</p> */}
          <h3>Viajes Populares</h3>
        </div>

        <div className="tour-one__carousel tour-one__carousel-no-overflow thm__owl-carousel owl-carousel owl-theme">
          <Swiper
            spaceBetween={30}
            breakpoints={{
              // when window width is >= 640px
              640: {
                width: 640,
                slidesPerView: 1,
              },
              // when window width is >= 768px
              768: {
                width: 768,
                slidesPerView: 2,
              },
            }}
          >
            {I18n.get('trips') &&
              I18n.get('trips').length > 0 &&
              I18n.get('trips').map((trip: any, index: number) => (
                <SwiperSlide key={index}>
                  <div className="item">
                    <div className="tour-one__single">
                      <div className="tour-one__image">
                        <img src={trip.data.main_image.url} alt="" />

                        <Link to={'/trip-detail/' + trip.id}>
                          <i className="fa fa-heart"></i>
                        </Link>
                      </div>
                      <div className="tour-one__content">
                        <div className="tour-one__stars">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                        <h3>
                          <Link to={'/trip-detail/' + trip.id}>
                            {trip.data['trip-title'][0].text}
                          </Link>
                        </h3>
                        <p>
                          <span>
                            {trip.data['price'] &&
                              `$ ${trip.data['price'].toLocaleString()}`}
                          </span>{' '}
                          / {`${trip.data['currency']}`} Por Persona
                        </p>
                        <ul className="tour-one__meta list-unstyled">
                          <li>
                            <Link to={'/trip-detail/' + trip.id}>
                              <i className="fas fa-clock"></i>
                              {`${trip.data['days'][0].text} Días `}
                            </Link>
                          </li>
                          <li>
                            <Link to={'/trip-detail/' + trip.id}>
                              <i className="fa fa-user-circle"></i> 0+
                            </Link>
                          </li>
                          <li>
                            <Link to={'/trip-detail/' + trip.id}>
                              <i className="fa fa-map"></i>
                              {/* {trip.cities.length && `${trip.cities[0]}`} */}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default PopularTours
