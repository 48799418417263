/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0dff0043-3d1a-45b2-a454-732a2bbad520",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_aSgmnYxdG",
    "aws_user_pools_web_client_id": "78eqcnjkb090eemkr2avle7qkp",
    "oauth": {
        "domain": "comviajesaeromare50720eb-e50720eb-test.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://staging.viajesaeromar.com/",
        "redirectSignOut": "http://localhost:3000/,https://staging.viajesaeromar.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://5xemwsswirf3lmvwtwrrxyf7ru.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-bio25zmxinbgbjlx7uuokq4m74"
};


export default awsmobile;
