import React, { useEffect } from 'react'
import { useContentContext } from '../context/ContentContext'
import Hero from './layout/Hero'
import PopularTours from './trips/PopularTours'
import ToursByType from './trips/ToursByType'
import { I18n } from 'aws-amplify'

const Home = () => {
  const { loadContent } = useContentContext()

  useEffect(() => {
    //loadContent('search_by_type_box')
    loadContent('explore_box')
  }, [])
  return (
    <>
      <Hero />
      {/* <section className="tour-search-one__home-two">
        <div className="container">
          <form
            className="tour-search-one wow fadeIn"
            action="tour-sidebar.html"
            data-wow-duration="1500ms"
          >
            <div className="tour-search-one__inner">
              <div className="tour-search-one__inputs">
                <div className="tour-search-one__input-box">
                  <label>Where to</label>
                  <input
                    type="text"
                    placeholder="Enter keywords"
                    name="place"
                    id="place"
                  />
                </div>
                <div className="tour-search-one__input-box">
                  <label>When</label>
                  <input
                    type="text"
                    placeholder="September"
                    name="when"
                    id="when"
                  />
                </div>
                <div className="tour-search-one__input-box">
                  <label>Type</label>
                  <select className="selectpicker" id="type">
                    <option value="Adventure">Adventure</option>
                    <option value="Adventure">Adventure</option>
                    <option value="Wildlife">Wildlife</option>
                    <option value="Sightseeing">Sightseeing</option>
                  </select>
                </div>
              </div>
              <div className="tour-search-one__btn-wrap">
                <button type="submit" className="thm-btn tour-search-one__btn">
                  Find now
                </button>
              </div>
            </div>
          </form>
        </div>
      </section> */}
      <ToursByType />
      <PopularTours />
      {I18n.get('exploreBox') && I18n.get('exploreBox').image && (
        <section
          className="cta-four"
          style={{
            backgroundImage: `url(${I18n.get('exploreBox').image.url})`,
          }}
        >
          <div className="container">
            <p>
              {I18n.get('exploreBox') && I18n.get('exploreBox').second_caption}
            </p>
            <h3>
              {I18n.get('exploreBox') && I18n.get('exploreBox').main_caption}{' '}
              <span>
                {I18n.get('exploreBox') && I18n.get('exploreBox').location}
              </span>
            </h3>
            {/* <a href="tour-details.html" className="thm-btn cta-four__btn">
            Start Booking now
          </a> */}
          </div>
        </section>
      )}
    </>
  )
}

export default Home
