import React from 'react'
import './assets/css/bootstrap.min.css'
import './assets/css/bootstrap-select.min.css'
import './assets/css/tripo-icons.css'
import './assets/css/theme.css'
import './assets/css/responsive.css'
import 'font-awesome/css/font-awesome.min.css'

import { Switch, Route, Link, withRouter } from 'react-router-dom'

// components
import Header from './components/layout/Header'
import Home from './components/Home'
import Contact from './components/Contact'
import TripDetail from './pages/TripDetail'
import GAListener from './components/GAListener'

const App = () => {
  const trackingId = 'UA-17045518-1'
  return (
    <>
      <div className="page-wrapper">
        <Header />
        <GAListener trackingId={trackingId}>
          <Switch>
            <Route exact path="/trip-detail/:id" component={TripDetail} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/" component={Home} />
          </Switch>
        </GAListener>
        <div className="site-footer__bottom">
          <div className="container">
            <p>
              <Link to={'/'}>viajesaeromar.com</Link>
            </p>
            <div className="site-footer__social">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={
                  'https://api.whatsapp.com/send?phone=573136724959&text=Hola%2C%20estoy%20interesad%40%20en%20los%20servicios%20turisticos%20que%20publicas%20en%20viajesaeromar.com'
                }
              >
                <i className="fa fa-whatsapp"></i>
              </Link>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={'https://www.facebook.com/viajesaeromarsgv'}
              >
                <i className="fa fa-facebook-square"></i>
              </Link>
              {/* <Link to={"#"}>
                <i className="fa fa-instagram"></i>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter<any, any>(App)
