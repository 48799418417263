import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useContentContext } from '../../context/ContentContext'

const ToursByType = () => {
  const { listTypeTours, tourTypeList } = useContentContext()

  useEffect(() => {
    listTypeTours()
  }, [])

  return (
    <section className="tour-category-one">
      <div className="container">
        <div className="block-title text-center">
          <p>Negocios y Turismo</p>
          <h3>Viajes Acorde a tu Necesidad</h3>
        </div>
        <div className="row">
          {tourTypeList.length &&
            tourTypeList.map((typeList: any, index: number) => (
              <div
                key={index}
                className="tour-category-one__col wow fadeInUp "
                data-wow-duration="1500ms"
                data-wow-delay="000ms"
              >
                <div className="tour-category-one__single">
                  <i className={typeList.uid}></i>
                  <h3>
                    <Link to="tour-standard.html">
                      {typeList?.data['travel_type'][0].text} <br />{' '}
                      {typeList?.data['category'][0].text}
                    </Link>
                  </h3>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default ToursByType
