import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import { withRouter } from 'react-router-dom'

function sendPageView(location: any) {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
}

function GAListener({ children, trackingId, history }: any) {
  useEffect(() => {
    ReactGA.initialize(trackingId)
    sendPageView(history.location)
    return history.listen(sendPageView)
  }, [history, trackingId])

  return children
}

GAListener.propTypes = {
  children: PropTypes.node,
  trackingId: PropTypes.string,
  history: PropTypes.shape({
    listen: PropTypes.func,
  }),
}

export default withRouter(GAListener)
