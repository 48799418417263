import React, { useEffect } from 'react'
import { useHeroContext, Hero as IHero } from '../../context/HeroContext'
import './../../assets/css/slider.scss'

const Hero = () => {
  const { heros, loadHeros, dispatch } = useHeroContext()

  const nextSlide = () => {
    const currentIndex = heros.findIndex((item: IHero) => item.active === true)
    dispatch({
      type: 'SET_HEROS',
      payload: heros.map((item: IHero) => ({ ...item, active: false })),
    })
    const nextIndex = (currentIndex + 1) % heros.length
    let arr = heros.map((item: IHero) => ({ ...item, active: false }))
    arr[nextIndex].active = true
    dispatch({ type: 'SET_HEROS', payload: arr })
  }

  useEffect(() => {
    let intervalId = setInterval(() => {
      nextSlide()
    }, 25000)

    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    loadHeros('her')
  }, [])

  return (
    <section className="banner-two">
      <div className="banner-two__bg">
        <div className="css-slider">
          {heros.map((hero: IHero, index: number) => (
            <input
              key={index}
              type="radio"
              name="slider-navigation"
              id={index === 0 ? 'first-slide' : 'second-slide'}
              className={hero.active ? 'active' : ''}
            />
          ))}
          <div className="css-slider-navigation">
            {heros.map((hero: IHero, index: number) => (
              <label
                key={index}
                className="css-slider-navigation-item"
                htmlFor={index === 0 ? 'first-slide' : 'second-slide'}
                onClick={() => nextSlide()}
              ></label>
            ))}
          </div>
          <div className="css-slider-wrapper">
            <div className="css-slider-inner">
              {heros.map((hero: IHero, index: number) => (
                <div
                  key={index}
                  id={
                    index === 0 ? 'actual-first-slide' : 'actual-second-slide'
                  }
                  className="css-slider-item"
                  style={{ backgroundImage: `url("${hero.image}")` }}
                >
                  <div className="css-slider-item-inner">
                    <div className="container">
                      <p>{hero.main_caption}</p>
                      <h2>{hero.second_caption}</h2>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <img
        src="assets/images/shapes/banner-two__flotated-text.png"
        alt=""
        className="banner-two__floated-text"
      />
      <div className="container">
        <p>&nbsp;</p>
        <h2>&nbsp;</h2>
      </div>
    </section>
  )
}

export default Hero
